import {Container,Navbar} from 'react-bootstrap';

function Header(){
    return (
        <>
         <Navbar bg="dark" variant="dark">
            <Container fluid>
            <Navbar.Brand href="/">Commemorative Euro Coins</Navbar.Brand>    
            </Container>
        </Navbar>
        </>
    );
}

export default Header;