import './App.css';
import Header from './Components/Header'
import Home from './Components/Home'
import Year from './Components/Year'
import Login from './Components/Login'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useToken from './CustomHooks/useToken'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';

function App() {
  const {token,setToken} = useToken();
  useEffect(() => {
    document.title = 'Commemorative Euro Coins';
  });
  if(!token){
    return <>
    <Header/>
    <Login setToken={setToken}/>
    </>
  }
  return (
    <>
    <Header/>
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home token={token}/>}/>
          <Route path='/year' element={<Year token={token} params={''}/>}/>
        </Routes>
      </BrowserRouter>
    </>  
  );
}

export default App;
