import {useState, useEffect, useRef} from 'react';
import {Card, Container, Col, Row, Button, Form, Modal} from 'react-bootstrap';
// const base_url = 'http://vmi380662.contaboserver.net:7000';
const base_url = window.location.protocol + '//' + window.location.host; 
function Year({token}){
    
    const [coins,setCoins] = useState([]);
    const [params, setParams] = useState('');
    // used to re-render after coin update
    const [upd, setUpd] = useState();
    // used to handle checkbox
    const [check, setCheck] = useState(false);
    // used to handle navigation
    const [year, setYear] = useState(new URLSearchParams(window.location.search).get('year'));
    const [leftDisable, setLeftDisable] = useState(year*1===2004 ? true : false);
    const [rightDisable, setRightDisable] = useState(year*1===2023 ? true : false);
    const [scrollPosition, setSrollPosition] = useState(0);
    const [scrollVisible, setScrollVisible] = useState(false);
    const [count, setCount] = useState(0);
    const [having, setHaving] = useState(0);
    const refScrollUp = useRef();

    // used to add modal of update
    const [show, setShow] = useState(false);
    const handleShow = () => {setShow(true);};
    const handleHide = () => {setShow(false);};
    const [title,setTitle] = useState('');
    const [coinId,setCoinId] = useState(0);
    const [coinVal,setCoinVal] = useState('NO');
    const [status, setStatus] = useState('');
    //const year = new URLSearchParams(window.location.search).get('year');
    useEffect(() => {
        fetch(base_url+'/coins?where=COIN_YEAR='+year+params,{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setCoins(data);
        })
        .catch( err => {
            console.error(err);
        });
        if(leftDisable === true){
            document.getElementById('leftButton').disabled=true;
        }else{
            document.getElementById('leftButton').disabled=false;
        }
        if(rightDisable === true){
            document.getElementById('rightButton').disabled=true;
        }else{
            document.getElementById('rightButton').disabled=false;
        }
        window.addEventListener("scroll", () => {
            const position = window.pageYOffset;
            setSrollPosition(position);
    
            if (scrollPosition > 150) {
                setScrollVisible(!scrollVisible);
                document.getElementById('scrollToTop').style.visibility='visible';
            } else if (scrollPosition <= 150) {
                setScrollVisible(!scrollVisible);
                document.getElementById('scrollToTop').style.visibility='hidden';
            }
        });
        // prelevo il count totale di quest'anno
        fetch(base_url+'/coins?fields=count(*)&where=COIN_YEAR='+year,{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setCount(data[0]['count(*)']);
        })
        .catch( err => {
            console.error(err);
        });
        // prelevo il count delle totali possedute
        fetch(base_url+'/coins?fields=count(*)&where=COIN_YEAR='+year+',COIN_POSSESSION=\'SI\'',{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setHaving(data[0]['count(*)']);
        })
        .catch( err => {
            console.error(err);
        });
    }, [token,year,params, upd, check, leftDisable, rightDisable, scrollVisible, having]);

    const countryList = coins.map((coin) => {
        return coin.COIN_COUNTRY;
    });
    const distList = [...new Set(countryList)];

    const handleOnClickOption = (e, country) => {
        if(country === 'all' || country === 'Tutti i paesi') setParams('');
        else setParams(",COIN_COUNTRY='"+country+"'");
    };

    const handleUpdate = (coinId, val) => {
        fetch(base_url+'/coin/'+coinId,{
            method:'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization' : token
            },
            body: JSON.stringify({
                'COIN_POSSESSION': val
            })})
        .then(response => {
            console.log(coinId);
            response.json();
        })
        .then(data => {
            console.log(val);
            console.log(data);
            setUpd(val+coinId);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const handleCheck = () => {
        if(check === false){
            setParams(params+",COIN_POSSESSION='NO'");
            setCheck(true);
        }else{
            setParams(params.replace(",COIN_POSSESSION='NO'",""));
            setCheck(false);
        }
    };

    const handleScrollUp = () => {
        refScrollUp.current.scrollIntoView({ behavior: "smooth" });
        setScrollVisible(!scrollVisible);
        document.getElementById('scrollToTop').style.visibility='hidden';
    };
    return (
        <>
            <>
            <Modal show={show} onHide={handleHide} title={title} coinId={coinId} coinVal={coinVal}>
                <Modal.Header closeButton>
                <Modal.Title>Attenzione</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di aver {status} la moneta '{title}'?</Modal.Body>
                <Modal.Footer>
                <Button variant="dark" onClick={() => {handleUpdate(coinId,coinVal);handleHide();}}>
                    Si
                </Button>
                <Button variant="secondary" onClick={handleHide}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>

            <Container className="mt-5 mb-5" ref={refScrollUp}>
            <Row>
                <Col className="text-center display-2 mb-5">
                    <Button variant='dark' id='leftButton'
                        size='lg'
                        onClick={() => {
                            setYear(1*year-1);
                            setRightDisable(false);
                            if(1*year-1 === 2004){
                                setLeftDisable(true);
                            }
                        }}
                    >
                        {'<'}
                    </Button>
                </Col>
                <Col xs={6}>
                    <h1 className="text-center display-2 mb-5">
                        Euro {year}
                    </h1>
                </Col>
                <Col className="text-center display-2 mb-5">
                    <Button variant='dark' id='rightButton'
                    size='lg'
                    onClick={() => {
                        setYear(1*year+1);
                        setLeftDisable(false);
                        if(1*year+1 === 2023){
                            setRightDisable(true);
                        }
                    }}
                    >
                        {'>'}
                    </Button>
                </Col>
            </Row>
            
            <Form.Select className="mb-5" size="lg" onChange={(e) => handleOnClickOption(e,e.target[e.target.selectedIndex].value)}>
                <option key='all' onClick={(e) => {handleOnClickOption(e,'all')}}>Tutti i paesi</option>
                {distList.map((country) => {
                    return <option key={country} onMouseDown={(e) => handleOnClickOption(e,country)}>{country}</option>
                })}
            </Form.Select>
            <Form.Check 
                type='checkbox'
                id='checkbox-possessed'
                label='Escludi le monete già possedute'
                onChange={() => {
                    handleCheck();
                }}
                className="mb-2"
            />
            <p className='mb-2'>Possedute: {having}/{count}</p>

            <Row lg={3} md={3} xs={1} sm={2}>
            {coins.map((coin,i) => {
                return <Col key={coin.COIN_ID} className="d-flex">
                    <Card style={{transition: "all 7s"}} className="flex-fill">
                        <Card.Img variant="top" src={base_url+"/images/"+coin.COIN_YEAR+"/"+coin.COIN_IMAGE} />
                        <Card.Body className="text-center">
                        <Card.Title>{coin.COIN_TITLE}</Card.Title>
                        <Card.Subtitle>Paese: {coin.COIN_COUNTRY}<br/>Tiratura: {Intl.NumberFormat().format(coin.COIN_TIRATURA)}<br/> Posseduta: {coin.COIN_POSSESSION}</Card.Subtitle>
                        <Button 
                            variant={coin.COIN_POSSESSION === 'NO' ? "success" : "error"}
                            size="lg" 
                            onClick={
                            (e) => {
                                e.preventDefault();
                                let val = coin.COIN_POSSESSION === 'NO' ? 'SI' : 'NO';
                                setStatus(val === 'NO' ? 'perso' : 'trovato');
                                //handleUpdate(coin.COIN_ID,val);
                                setTitle(coin.COIN_TITLE);
                                setCoinId(coin.COIN_ID);
                                setCoinVal(val);
                                handleShow();
                            }
                            }
                        >{coin.COIN_POSSESSION === 'NO' ? 'Trovata' : 'Persa'}
                        </Button>
                        </Card.Body>
                    </Card>
                </Col>;
            })}
            </Row>
            
            </Container>
            <Button id="scrollToTop" 
            size='lg'
            variant='dark'
            style={
                {
                    float:'right',
                    position:'fixed',
                    right:'5%',
                    bottom:'7%',
                    maxHeight:'80%',
                    visibility: 'hidden'
                }}onClick={handleScrollUp}>
                {'^'}
            </Button>
        </>
        </>
    );
}

export default Year;