import React, {useState, useEffect} from 'react';
import {Card, Container, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom'
//const base_url = 'http://vmi380662.contaboserver.net:7000';
const base_url = window.location.protocol + '//' + window.location.host; 

function Home({token}){
    // used to fetch all coins years
    const [coins, setCoins] = useState([]);
    // used to fetch total possessed and absolute total
    const [count, setCount] = useState(0);
    const [having, setHaving] = useState(0);
    // used to group by possessed to display its count
    const [grouped, setGrouped] = useState([]);
    useEffect(() => {
        fetch(base_url+'/coins?fields=COUNT(*),COIN_YEAR&groupBy=true&order=COIN_YEAR',{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setCoins(data);
            
        })
        .catch( err => {
            console.error(err);
        });

        // possedute
        fetch(base_url+'/coins?fields=COUNT(*)&where=COIN_POSSESSION=\'SI\'',{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setHaving(data[0]['COUNT(*)']);
        })
        .catch( err => {
            console.error(err);
        });
        // totali
        fetch(base_url+'/coins?fields=COUNT(*)',{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setCount(data[0]['COUNT(*)']);
        })
        .catch( err => {
            console.error(err);
        });

        // raggruppamento per possedute/anno
        fetch(base_url+'/coins?fields=COUNT(*),COIN_YEAR&groupBy=true&order=COIN_YEAR&where=COIN_POSSESSION=\'SI\'',{headers: {'authorization' : token}})
        .then(response => response.json())
        .then(data => {
            setGrouped(data);
        })
        .catch( err => {
            console.error(err);
        });
    }, [token, count, having, grouped]);

    return (
        <>
            <Container className="mt-5 mb-5">
            <h1 className="display-1 text-center mb-5">Tutti gli anni</h1>
            <p className='mb-2'>Possedute in totale: {having}/{count}</p>
            <Row lg={3} md={3} xs={1} sm={2}>
            {coins.map((coin,i) => {
                let g;
                if(grouped !== undefined && grouped[i] !== undefined){
                    g=grouped[i]['COUNT(*)'];
                }else{
                    g='0';
                }
                return <Col key={coin.COIN_YEAR} className="d-flex">
                    <Card className="flex-fill">
                        <Card.Img variant="top" src={require("../img/"+coin.COIN_YEAR+"_cover.jpg")} />
                        <Card.Body className="text-center">
                        <Card.Title className="display-5">{coin.COIN_YEAR}</Card.Title>
                        <Card.Subtitle style={{fontSize:'1.2rem',marginBottom:'1%'}}>{g}/{coin['COUNT(*)']}</Card.Subtitle>
                        <Link className="btn btn-dark" to={"/year?year="+coin.COIN_YEAR}>Apri</Link>
                        </Card.Body>
                    </Card>
                </Col>;
            })}
            </Row>
            </Container>
        </>
    );
}

export default Home;