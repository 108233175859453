import {useState} from 'react';

function useToken(){
    const getToken = () => {
        const tokenString = sessionStorage.getItem('euro_token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token_value
    };

    const [token,setToken] = useState(getToken());

    const saveToken = (userToken) => {
        sessionStorage.setItem('euro_token', JSON.stringify(userToken));
        setToken(userToken.token_value);
    };

    return {
        setToken: saveToken,
        token
    }
}

export default useToken;